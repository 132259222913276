import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import { Link } from "gatsby";
import Navbar from "../components/navbar";
import {
  IntroContainer,
  IntroSpacing,
  HeroTitle,
} from "../components/styles/styles";
import styled from "styled-components";
import RoseImg from "../images/roses1.png";
import SEO from "../components/seo";

const Prices = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: baseline;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const Price = styled.div`
  background: white;
  color: #404040;
  border-radius: 5px;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const PriceTitle = styled.h2`
  border-bottom: 1px solid white;
  display: inline-block;
  margin-bottom: 1rem;
`;

const Italic = styled.i`
  display: block;
  font-weight: 300;
  font-size: 0.9rem;
`;

const Hr = styled.hr`
  margin: 5rem 0;
`;

const PhoneNumber = styled.a`
  display: inline-block;
  color: white;
  font-weight: bold;
  color: #333d29;
  font-family: "Playfair Display", serif;
`;

const Priser = () => {
  return (
    <Layout>
      <SEO
        title="Ellies. Priser"
        description="Här hittar du priser för Ellies, din lokala frisör i Lit, Östersund."
      />
      <main>
        <IntroContainer>
          <Navbar />
          <IntroSpacing>
            <Container>
              <HeroTitle>Priser</HeroTitle>
              <p>
                Priser varierar beroende på längd och tjocklek. Är du osäker på
                vad som gäller för dig är du välkommen att kontakta mig.
              </p>
              <p>
                Är du osäker på om det är balayage eller slingor du vill ha? Du
                kanske vill ha någon extra effekt i håret? Kontakta mig innan du
                bokar så hjälper jag dig. Via tel{" "}
                <PhoneNumber href="tel:+4670-670 70 04">
                  070-670 70 04
                </PhoneNumber>{" "}
                eller gå in på <a className="green-text" target="_blank" href="https://bokning.voady.se/ellislit/categories">bokningssidan.</a>
              </p>
             {/* <p>
                Nedan ser du priser för några av alla behandlingar som finns att
                boka.
              </p>
               <Hr></Hr>
              <Prices>
                <Price>
                  <PriceTitle>Klippning</PriceTitle>
                  <Italic>(varierar beroende på längd och tjocklek)</Italic>
                  <p>500-850:- </p>
                </Price>
                <Price>
                  <PriceTitle>Klippning barn</PriceTitle>
                  <p>300-450:-</p>
                </Price>
                <Price>
                  <PriceTitle>Klippning exklusiv</PriceTitle>
                  <Italic>(varierar beroende på längd och tjocklek)</Italic>
                  <p>600-900:-</p>
                </Price>
                <Price>
                  <PriceTitle>Permanent ink klipp</PriceTitle>
                  <Italic>(varierar beroende på längd och tjocklek)</Italic>
                  <p>1.500-2.450:-</p>
                </Price>
                <Price>
                  <PriceTitle>Klipp och färg</PriceTitle>
                  <Italic>(varierar beroende på längd och tjocklek)</Italic>
                  <p>1.400-1.925:-</p>
                </Price>
                <Price>
                  <PriceTitle>Färg (en färg)</PriceTitle>
                  <Italic>(varierar beroende på längd och tjocklek)</Italic>
                  <p>1.125-1.690:-</p>
                </Price>
                <Price>
                  <PriceTitle>Frans/Bryn</PriceTitle>
                  <p>Fransfärg 200:-</p>
                  <p>Brynfärg ink plock 280:-</p>
                  <p>Frans och brynfärg ink plock 450:-</p>
                </Price>
                <Price>
                  <PriceTitle>Inpackning (olaplex)</PriceTitle>
                  <Italic>(varierar beroende på längd och tjocklek)</Italic>
                  <p>600-1400:-</p>
                </Price>
              </Prices> */}
            </Container>
          </IntroSpacing>
        </IntroContainer>
      </main>
    </Layout>
  );
};

export default Priser;
